import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LIST_SECRETS, LIST_SECRET_TYPES } from '../../../../graphql/query';
import { useMutation, useQuery } from '@apollo/client';
import { SecretType, SecretTypeMetadata, SiteSecretInfo } from '../../../../../generated/gql/graphql';
import { Alert, Button, Dialog, IconButton, LinearProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import { ADD_SECRET, DELETE_SECRET } from '../../../../graphql/mutation';
import Ajv from 'ajv';
import { Icon } from '@iconify/react';

import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { AppContext } from '../../../../contexts/AppContext';
import StaticParamEditor from '../../../../components/pixie/param-editor/DEPRECATED/StaticParamEditor';


function getIcon(secretType: SecretType): React.ReactElement {
  switch (secretType) {
    case SecretType.AmazonAds:
      return <Icon icon='ri:amazon-fill' style={{ height: 24, width: 24 }} />;
    case SecretType.OpenAi:
      return <Icon icon='ri:openai-fill' style={{ height: 24, width: 24 }} />;
    case SecretType.Reddit:
      return <Icon icon='logos:reddit-icon' style={{ height: 24, width: 24 }} />;
    case SecretType.X:
      return <Icon icon='ri:twitter-x-fill' style={{ height: 24, width: 24 }} />;
    case SecretType.WhatsApp:
      return <Icon icon='logos:whatsapp-icon' style={{ height: 24, width: 24 }} />;
    case SecretType.Anthropic:
      return <Icon icon='simple-icons:anthropic' style={{ height: 24, width: 24 }} />;
    case SecretType.GoogleVertexAi:
      return <Icon icon='ri:gemini-line' style={{ height: 24, width: 24 }} />;
  }
}


export function LinkedAccountButton(props: {
  metadata: SecretTypeMetadata,
  info?: SiteSecretInfo,
  onEdit: () => void,
  onDelete: () => void,
}): React.ReactElement {
  const theme = useTheme();
  const icon = getIcon(props.metadata.type);

  return <Stack
    direction='row'
    spacing={2}
    display='flex'
    alignItems='center'
    justifyContent='space-between'
    sx={{ p: 2, borderRadius: 5, border: `1px solid ${theme.palette.divider}` }}
  >
    <Stack direction='row' spacing={1} display='flex' alignItems='center'>
      {icon}
      <Typography variant='subtitle1'>{props.metadata.type}</Typography>
      {/* <IconButton onClick={}><DeleteOutlineRoundedIcon color='error' /></IconButton> */}

    </Stack>
    {props.info
      ? <Stack direction='row'>
        <IconButton onClick={props.onEdit} sx={{
          '&:hover': {
            color: theme.palette.secondary.main,
          }
        }}><ModeEditOutlineOutlinedIcon fontSize='inherit' /></IconButton>
        <IconButton onClick={props.onDelete} sx={{
          '&:hover': {
            color: theme.palette.error.main,
          }
        }}><HighlightOffOutlinedIcon fontSize='inherit' /></IconButton>
      </Stack>
      : <IconButton onClick={props.onEdit} sx={{
        '&:hover': {
          color: theme.palette.secondary.main,
        }
      }}><AddLinkRoundedIcon fontSize='inherit' /></IconButton>
    }

  </Stack>
}


export function SecretEditor(props: {
  siteId: string,
  metadata: SecretTypeMetadata | undefined,
  onUpdate: () => void,
  onClose: () => void,
}): React.ReactElement {
  const { setError, setSuccessMessage } = useContext(AppContext);
  const [addSecret] = useMutation(ADD_SECRET);
  const [saving, setSaving] = useState(false);
  const [secretValue, setSecretValue] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [accountName, setAccountName] = useState('');

  useEffect(() => {
    if (props.metadata) {
      const schema = props.metadata.schema;
      const errors = [] as string[];
      if (schema) {
        const ajv = new Ajv({ strict: false });
        const validate = ajv.compile(schema);
        validate(secretValue);
        if (validate.errors) {
          for (const e of validate.errors) {
            errors.push((e.instancePath ? e.instancePath + ': ' : '') + e.message);
          }
        }
      }
      setValidationErrors(errors);
    }
  }, [props.metadata, secretValue]);

  const handleSaveSecret = () => {
    if (!secretValue || !props.metadata || validationErrors.length > 0) return;
    setSaving(true);
    addSecret({
      variables: {
        siteId: props.siteId,
        name: accountName || props.metadata.type,
        secretType: props.metadata.type,
        value: secretValue,
      }
    })
      .then(res => {
        if (!res.data) setError(res.errors?.[0] || "Something went wrong.");
        setSuccessMessage(`Successfully linked ${props.metadata?.type} account.`);
        props.onUpdate();
        props.onClose();
      })
      .catch(setError)
      .finally(() => setSaving(false));
  }

  return <Dialog open={Boolean(props.metadata)} onClose={props.onClose} maxWidth='sm' fullWidth>
    {props.metadata && <Stack spacing={2} p={2}>
      <Stack direction='row' spacing={1} display='flex' alignItems='center'>
        {getIcon(props.metadata.type)}
        <Typography variant='subtitle1'>{props.metadata.type}</Typography>
      </Stack>
      {validationErrors.length > 0
        ? <Alert severity='error'>{validationErrors.map((e, idx) => <Typography key={idx}>{e}</Typography>)}</Alert>
        : undefined
      }
      <TextField
        label='Account name'
        variant='standard'
        value={accountName}
        onChange={e => setAccountName(e.target.value)}
        placeholder={props.metadata.type}
      />
      <StaticParamEditor
        schema={props.metadata.schema}
        value={secretValue}
        onChange={setSecretValue}
        resetTrigger={props}
      />
      {saving
        ? <LinearProgress />
        : <Stack direction='row' spacing={1} display='flex' justifyContent='center'>
          <Button
            variant='contained'
            disabled={validationErrors.length > 0}
            onClick={handleSaveSecret}
          >
            Save
          </Button>
          <Button variant='outlined' onClick={props.onClose}>Cancel</Button>
        </Stack>
      }
    </Stack>
    }
  </Dialog>
}


export function LinkedAccountPanel(props: {
  siteId: string,
}): React.ReactElement {
  const [deleteSecret] = useMutation(DELETE_SECRET);
  const secretTypesQuery = useQuery(LIST_SECRET_TYPES);
  const secretTypes = useMemo(() => {
    const ret: Partial<{ [key in SecretType]: SecretTypeMetadata }> = {};
    for (const stMetadata of (secretTypesQuery.data?.alistSecretTypes || [])) {
      ret[stMetadata.type] = stMetadata;
    }
    return ret;
  }, [secretTypesQuery.data]);

  const { setError, setSuccessMessage } = useContext(AppContext);
  const { loading, refetch, data } = useQuery(LIST_SECRETS, {
    variables: { siteId: props.siteId },
    onError: setError,
  });
  const secrets = data?.alistSecrets || [];

  const [secretTypeInEdit, setSecretTypeInEdit] = useState<SecretType | null>(null);

  const handleDeleteSecret = async (secretId: string, secretType: SecretType) => {
    await deleteSecret({
      variables: {
        siteId: props.siteId,
        secretId,
      }
    })
      .then(res => {
        if (!res.data) setError(res.errors?.[0] || "Something went wrong.");
        setSuccessMessage(`${secretType} linked account removed.`);
        refetch();
      })
      .catch(setError);
  }


  return <>
    <Stack spacing={1}>
      {loading
        ? <LinearProgress />
        : Object.entries(secretTypes).map(
          ([st, metadata]) => {
            const info = secrets.find(s => s.type == st);
            return <LinkedAccountButton
              key={st}
              metadata={metadata}
              info={info}
              onEdit={() => setSecretTypeInEdit(metadata.type)}
              onDelete={() => {
                if (info) {
                  handleDeleteSecret(info.id, metadata.type)
                    .then(() => {
                      setSecretTypeInEdit(null);
                      refetch();
                    });
                }
              }}
            />
          }
        )}
    </Stack>
    <SecretEditor
      siteId={props.siteId}
      metadata={secretTypeInEdit ? secretTypes[secretTypeInEdit] : undefined}
      onUpdate={() => refetch()}
      onClose={() => { setSecretTypeInEdit(null) }}
    />
  </>
}
