import { LexicalEditor, TextNode, $getSelection, $isRangeSelection, KEY_ENTER_COMMAND, COMMAND_PRIORITY_HIGH, KEY_ESCAPE_COMMAND, KEY_ARROW_UP_COMMAND, KEY_ARROW_DOWN_COMMAND, KEY_TAB_COMMAND, $getNodeByKey, ElementNode, RangeSelection } from 'lexical';
import { useCallback, useEffect, useRef } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  MenuTextMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import React from 'react';
import { ComplexDynamicValue, DynamicValue, isComplex, isSimple, parseDistance } from '../../../../../types/DynamicValueTypes';
import { Stack, Typography } from '@mui/material';
import { $createDynamicValueNode, DynamicValueNode } from '../nodes/DynamicValueNode';
import AutoSuggestMenu from '../AutoSuggestMenu';
import { useEditorStore } from '../../../../../hooks/EditorState';
import { getSchemaDef, typeToString } from '../../../../../utils/dynamic-value-utils';
import { DataRefAutoSuggestOption, useSchemaDefForReference, getDataRefAutoSuggestOptions } from '../../../DataRefPopover';
import { FlowNodeName } from '../../../../../features/Pixie/Editor/FlowNodeName';
import { TypeString } from '../../../common';
import { useShallow } from 'zustand/react/shallow';
import { DynamicValueDisplay } from '../../../DynamicValueDisplay';
import { $createComplexDynamicValueNode, ComplexDynamicValueNode } from '../nodes/ComplexDynamicValueNode';

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
  "[" +
  TRIGGERS +
  "]" +
  "((?:" +
  VALID_CHARS +
  VALID_JOINS +
  "){0," +
  LENGTH_LIMIT +
  "})" +
  ")$"
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
  "[" +
  TRIGGERS +
  "]" +
  "((?:" +
  VALID_CHARS +
  "){0," +
  ALIAS_LENGTH_LIMIT +
  "})" +
  ")$"
);


// NOTE: the matching string for the reference case is without the '@' sign
function useAutoSuggestOptions(queryString: string, baseValue: DynamicValue | null | undefined): DataRefAutoSuggestOption[] {
  const referencedSchema = useSchemaDefForReference(baseValue?.reference, "result");
  const referencedNodeIdsByDistance = useEditorStore(state => {
    const distance = parseDistance('@' + queryString);
    return distance !== null ? state.actions.graph.getRelatedNodeIds(distance) : [];
  });
  // make the selector return an object instead of array of objects to minimize rerender
  const nodeIdWithNames = useEditorStore(useShallow(state => {
    let ret: { [id: string]: string } = {};
    for (const node of state.graph.nodes) {
      ret[node.id] = node.data.displayName;
    }
    return ret;
  }));
  const typeInfos = useEditorStore(state => state.actions.graph.getTypeInfo());

  if (baseValue) {
    const leafSchema = getSchemaDef(referencedSchema, baseValue.access_path);
    return getDataRefAutoSuggestOptions(leafSchema, baseValue.reference, baseValue.access_path).filter(option => {
      if (queryString) {
        return option.type === 'property' && option.key.startsWith(queryString);
      }
      else {
        return true;
      }
    });
  }

  // TODO currently autosuggest with related reference doesn't work, need to fix
  // If the typed text is a number, return a single DV with related reference
  if (referencedNodeIdsByDistance.length > 0) {
    return [{ type: 'reference', reference: '@' + queryString }];
  }

  if (!queryString) {
    return Object.keys(nodeIdWithNames).map(nodeId => ({ type: 'reference', reference: nodeId }));
  }

  return Object.entries(nodeIdWithNames)
    .filter(([id, name]) => (
      name && name.toLowerCase().startsWith(queryString.toLowerCase())
      || typeInfos[id].pluginInfo.name.toLowerCase().startsWith(queryString.toLowerCase())
    )).map(([id]) => ({ type: 'reference', reference: id }));
}


type DataReferenceMatch = MenuTextMatch & {
  matchingDynamicValue: ComplexDynamicValue | DynamicValue | null;
  // this key will be present when DV node is part of match, e.g. [dv]||@..., [dv]
  matchingDynamicValueNodeKey?: string;
  // this key will be present when text is part of match, e.g. [dv]||@..., @...
  matchingTextNodeKey?: string;
}

const EMPTY_MATCH: DataReferenceMatch = {
  leadOffset: 0,
  matchingString: '',
  replaceableString: '',
  matchingDynamicValue: null,
}

//for "@..." or "[dv/cdv]||@...", return null to show list of nodes
// for [dv] or [cdv], return the dv or last value in the cdv.values
function getSimpleDynamicValueForAutosuggest(match: DataReferenceMatch): DynamicValue | null {
  if (match.matchingTextNodeKey || !match.matchingDynamicValue) {
    return null;
  }
  if (isSimple(match.matchingDynamicValue)) {
    return match.matchingDynamicValue;
  }
  else {
    return match.matchingDynamicValue.values[match.matchingDynamicValue.values.length - 1] || null;
  }
}


export default function DataReferenceAutoSuggestPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isAutoSuggestOpenRef = useRef(false);
  const [currentMatch, setCurrentMatch] = React.useState<DataReferenceMatch>(EMPTY_MATCH);

  const options = useAutoSuggestOptions(currentMatch.matchingString, getSimpleDynamicValueForAutosuggest(currentMatch));
  const typeInfo = useEditorStore(state => state.actions.graph.getTypeInfo());

  const isEligibleForAutoSuggest = useCallback(
    (editor: LexicalEditor): DataReferenceMatch => {
      const nodeMatch = editor.read(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return null;
        }

        if (!selection.isCollapsed()) {
          return null;
        }

        const focusNode = selection.focus.getNode();

        if (focusNode instanceof ElementNode) {
          const lastNodeBeforeCursor = focusNode.getChildAtIndex(selection.focus.offset - 1);

          if (lastNodeBeforeCursor instanceof DynamicValueNode
            || (lastNodeBeforeCursor instanceof ComplexDynamicValueNode && lastNodeBeforeCursor.__value.values.length > 0)
          ) {
            return {
              leadOffset: 0,
              matchingString: '',
              replaceableString: '',
              matchingDynamicValue: lastNodeBeforeCursor.__value,
              matchingDynamicValueNodeKey: lastNodeBeforeCursor.getKey(),
            }
          }
        }
        else if (focusNode instanceof TextNode) {
          // in case the focus is at the beginning of the text node, we check the previous node
          if (selection.focus.offset === 0) {
            const previousNode = focusNode.getPreviousSibling();
            if (previousNode instanceof DynamicValueNode
              || (previousNode instanceof ComplexDynamicValueNode && previousNode.__value.values.length > 0)
            ) {
              return {
                leadOffset: 0,
                matchingString: '',
                replaceableString: '',
                matchingDynamicValue: previousNode.__value,
                matchingDynamicValueNodeKey: previousNode.getKey(),
              }
            }
          }
          // in this branch cursor is following some non-empty text content
          // there are 3 cases to support:
          // 1. "@{prefix}" to start data reference (done)
          // 2. "||@" immediately following a DV or CDV to change to CDV (done)
          // 3. ".{prefix}" immediately following a DV or CDV to append to DV's access path (TODO)
          else {
            let text = focusNode.getTextContent().substring(0, selection.focus.offset);

            // case #2
            const prevNode = focusNode.getPreviousSibling();
            if (text.startsWith("||@") && (prevNode instanceof DynamicValueNode || prevNode instanceof ComplexDynamicValueNode)) {
              return {
                leadOffset: 0,
                matchingString: text.substring(3),
                replaceableString: text,
                matchingDynamicValue: prevNode.__value,
                matchingDynamicValueNodeKey: prevNode.getKey(),
                matchingTextNodeKey: focusNode.getKey(),
              }
            }

            // case #1
            let match = AtSignMentionsRegex.exec(text);
            if (match === null) {
              match = AtSignMentionsRegexAliasRegex.exec(text);
            }
            if (match !== null) {
              // The strategy ignores leading whitespace but we need to know it's
              // length to add it to the leadOffset
              const maybeLeadingWhitespace = match[1];

              const matchingString = match[3];
              return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2],
                matchingDynamicValue: null,
                matchingTextNodeKey: focusNode.getKey(),
              };
            }
          }
          return null;
        }
      });
      return nodeMatch;
    },
    [],
  );

  // Replace data reference node + text annotation right before the cursor with a replacement data reference node, if there's a match
  const updateMatchingDataReferenceWithAnnotation = useCallback(
    (
      editor: LexicalEditor,
      regex: { [Symbol.match](string: string): RegExpMatchArray | null; },
      getReplacementFn: (dataReferenceNode: DynamicValueNode, match: RegExpMatchArray) => DynamicValue | null,
    ): boolean => {
      const matchResult = editor.read(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return null;
        }

        if (!selection.isCollapsed()) {
          return null;
        }

        const focusNode = selection.focus.getNode();

        if (!(focusNode instanceof TextNode)) {
          return null;
        }

        const match = focusNode.getTextContent().substring(0, selection.focus.offset).match(regex)
        if (!match) {
          return null;
        }
        const possibleDataReferenceNode = focusNode.getPreviousSibling();

        // TODO support CDV
        if (!(possibleDataReferenceNode instanceof DynamicValueNode)) {
          return null;
        }

        return {
          dataReferenceNode: possibleDataReferenceNode,
          textNode: focusNode,
          offset: selection.focus.offset,
          replacement: getReplacementFn(possibleDataReferenceNode, match)
        };
      });

      if (!matchResult) {
        return false;
      }

      const { dataReferenceNode, textNode, offset, replacement } = matchResult;

      if (replacement) {
        editor.update(() => {
          const newNode = $createDynamicValueNode(replacement);
          dataReferenceNode.replace(newNode);
          const [annotation] = textNode.splitText(offset);
          annotation.remove();
        });
        return true;
      }
      return false;
    }, []
  );

  const replaceArrayIndexAnnotation = useCallback(
    (editor: LexicalEditor) => updateMatchingDataReferenceWithAnnotation(
      editor,
      /\[(\d+)\]$/,
      (dataReferenceNode, match) => {
        const idx = parseInt(match[1]);

        const resultSchemaDef = getSchemaDef(
          typeInfo[dataReferenceNode.__value.reference]?.pluginInfo?.dataResultSchema,
          dataReferenceNode.__value.access_path,
        );
        if (resultSchemaDef?.type === 'array') {
          return {
            ...dataReferenceNode.__value,
            access_path: [
              ...dataReferenceNode.__value.access_path,
              { type: 'arrayIndex', value: idx }
            ]
          }
        }
        return null;
      },
    ), [typeInfo]
  );

  const replaceVersionAnnotation = useCallback(
    (editor: LexicalEditor) => updateMatchingDataReferenceWithAnnotation(
      editor,
      /::(\d+)/,
      (dataReferenceNode, match) => {
        const idx = parseInt(match[1]);

        return {
          ...dataReferenceNode.__value,
          version_delta: idx
        }
      },
    ), []
  );

  const replaceDefaultValueAnnotation = useCallback(
    (editor: LexicalEditor) => updateMatchingDataReferenceWithAnnotation(
      editor,
      /^\|\|\{(.+)\}$/,
      (dataReferenceNode, match) => {
        let defaultVal = undefined;

        try {
          defaultVal = JSON.parse(match[1]); // Attempt to parse as JSON
        } catch {
          return null;
        }
        return {
          ...dataReferenceNode.__value,
          version_delta: dataReferenceNode.__value.version_delta === undefined ? 0 : dataReferenceNode.__value.version_delta,
          default: defaultVal,
        }
      },
    ), []
  );

  useEffect(() => {
    const unregister = editor.registerUpdateListener(() => {
      if (
        replaceArrayIndexAnnotation(editor)
        || replaceVersionAnnotation(editor)
        || replaceDefaultValueAnnotation(editor)
      ) {
        return;
      }

      const match = isEligibleForAutoSuggest(editor);
      if (match) {
        setCurrentMatch(match);
        setAnchorEl(editor.getElementByKey(match.matchingTextNodeKey || match.matchingDynamicValueNodeKey));
      }
      else {
        setCurrentMatch(EMPTY_MATCH);
        setAnchorEl(null);
      }
    });

    return unregister;
  }, [editor, typeInfo]);  // need to take depdency on typeInfo for isEligibleForArrayIndex

  useEffect(() => {
    const unregisterEnterHandler = editor.registerCommand(
      KEY_ENTER_COMMAND,
      () => isAutoSuggestOpenRef.current,
      COMMAND_PRIORITY_HIGH,
    )

    const unregisterEscapeHandler = editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      () => isAutoSuggestOpenRef.current,
      COMMAND_PRIORITY_HIGH,
    )

    const unregisterKeyUpHandler = editor.registerCommand(
      KEY_ARROW_UP_COMMAND,
      () => isAutoSuggestOpenRef.current,
      COMMAND_PRIORITY_HIGH,
    )

    const unregisterKeyDownHandler = editor.registerCommand(
      KEY_ARROW_DOWN_COMMAND,
      () => isAutoSuggestOpenRef.current,
      COMMAND_PRIORITY_HIGH,
    )

    const unregisterTabHandler = editor.registerCommand(
      KEY_TAB_COMMAND,
      () => isAutoSuggestOpenRef.current,
      COMMAND_PRIORITY_HIGH,
    )

    return () => {
      unregisterEnterHandler();
      unregisterEscapeHandler();
      unregisterKeyUpHandler();
      unregisterKeyDownHandler();
      unregisterTabHandler();
    }
  }, [editor]);



  const onSelectOption = useCallback(
    (
      selectedOption: DataRefAutoSuggestOption,
      match: DataReferenceMatch,
      closeMenu: () => void
    ) => {
      // NOTE: this is not supposed to happen, we expect arrayIndex option would not be rendered for selection
      if (selectedOption.type === 'arrayIndex') return;

      editor.update(() => {
        // remove the text that's part of the match
        if (match.matchingTextNodeKey) {
          $splitNodeContainingQuery(match).remove();
        }

        let nodeToInsert: DynamicValueNode | ComplexDynamicValueNode;
        // DV/CDV node is part of the match
        if (match.matchingDynamicValueNodeKey) {
          const matchedDvNode = $getNodeByKey(match.matchingDynamicValueNodeKey);
          matchedDvNode?.remove();
          // This case is that autosuggest is triggered after a CDV node
          // when the autosuggest is data reference, the new DV should be appended to CDV
          // when the autosuggest is property, the last DV in CDV values should be replaced
          if (matchedDvNode instanceof ComplexDynamicValueNode) {
            if (selectedOption.type === 'reference') {
              nodeToInsert = $createComplexDynamicValueNode({
                ...matchedDvNode.__value,
                values: [
                  ...matchedDvNode.__value.values,
                  { reference: selectedOption.reference, access_path: [] }
                ],
              })
            }
            else if (selectedOption.type === 'property') {
              nodeToInsert = $createComplexDynamicValueNode({
                ...matchedDvNode.__value,
                values: [
                  ...matchedDvNode.__value.values.slice(0, -1),
                  selectedOption.value
                ]
              })
            }
          }
          // This case is that autosuggest is triggered after a DV node
          // when the autosuggest is data reference (DV after DV, triggered by ||@) , a CDV should be created
          // when the autosuggest is property, the DV should be updated
          else if (matchedDvNode instanceof DynamicValueNode) {
            if (selectedOption.type === 'reference') {
              nodeToInsert = $createComplexDynamicValueNode({
                values: [matchedDvNode.__value, { reference: selectedOption.reference, access_path: [] }],
                precedence: 'latest',
                default: matchedDvNode.__value.default
              })
            }
            else {
              nodeToInsert = $createDynamicValueNode(selectedOption.value);
            }
          }

        }
        // only text match
        else {
          const dv: DynamicValue = selectedOption.type === 'reference'
            ? { reference: selectedOption.reference, access_path: [] }
            : selectedOption.value;
          nodeToInsert = $createDynamicValueNode(dv);
        }

        $getSelection().insertNodes([nodeToInsert]);
        // update match to the newly created node
        setCurrentMatch({
          leadOffset: 0,
          matchingString: '',
          replaceableString: '',
          matchingDynamicValue: nodeToInsert.__value,
          matchingDynamicValueNodeKey: nodeToInsert.getKey(),
        })
        closeMenu();
      });
    },
    [editor]
  );

  // TODO arrayIndex options should be filtered out from the options
  // and helper text should be displayed to instruct user to type [x] to select array index
  return <AutoSuggestMenu
    options={options}
    renderOptionFn={(option) => {
      switch (option.type) {
        case 'reference':
          return <Stack direction='row' spacing={2} width='100%' justifyContent='space-between' alignItems='center'>
            <DynamicValueDisplay dynamicValue={{ reference: option.reference, access_path: [] }} />
            {/* TODO the type is incorrect for related reference */}
            <TypeString>{typeToString(typeInfo[option.reference]?.pluginInfo?.dataResultSchema)}</TypeString>
          </Stack>;
        case 'property':
          return <Stack direction='row' spacing={2} width='100%' justifyContent='space-between' alignItems='center'>
            <Typography>{option.key}</Typography>
            <TypeString>{typeToString(option.schemaDef)}</TypeString>
          </Stack>;
        case 'arrayIndex':
          return undefined;
      }
    }}
    onSelectOption={(selectedOption) => {
      onSelectOption(selectedOption, currentMatch, () => setAnchorEl(null));
    }}
    anchorEl={anchorEl}
    onOpen={() => {
      isAutoSuggestOpenRef.current = true;
    }}
    onClose={() => {
      isAutoSuggestOpenRef.current = false;
      setAnchorEl(null);
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  />
}

// helper functions copied from LexicalMenu.ts, since the functions are not exported
// origin: https://github.com/facebook/lexical/blob/d64d395ce9db90912684346d0b06b57024dcc8a6/packages/lexical-react/src/shared/LexicalMenu.ts#L104

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 */
function getFullMatchOffset(
  documentText: string,
  entryText: string,
  offset: number,
): number {
  let triggerOffset = offset;
  for (let i = triggerOffset; i <= entryText.length; i++) {
    if (documentText.substr(-i) === entryText.substr(0, i)) {
      triggerOffset = i;
    }
  }
  return triggerOffset;
}


/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 * Common use cases include: removing the node, replacing with a new node.
 */
function $splitNodeContainingQuery(match: MenuTextMatch): TextNode | null {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return null;
  }
  const anchor = selection.anchor;
  if (anchor.type !== 'text') {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const selectionOffset = anchor.offset;
  const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
  const characterOffset = match.replaceableString.length;
  const queryOffset = getFullMatchOffset(
    textContent,
    match.matchingString,
    characterOffset,
  );
  const startOffset = selectionOffset - queryOffset;
  if (startOffset < 0) {
    return null;
  }
  let newNode;
  if (startOffset === 0) {
    [newNode] = anchorNode.splitText(selectionOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
  }

  return newNode;
}
