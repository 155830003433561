import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { RouteWithFade } from "../../components/animations";
import { SiteIdContext } from "../../contexts/SiteIdContext";
import {
  Route,
  Switch,
  useParams,
  useLocation,
} from 'react-router-dom';
import DismissibleDialog from '../../components/dismissible-dialog';
import ContactForm from '../../components/business-profile/contact-form';
import EditorPage from './Editor/EditorPage';
import { getEditorRoutePath } from '../../components/pixie/common';
import LandingPage from './Landing/LandingPage';
import { EmailSubscriptionContextProvider } from '../../contexts/EmailSubscriptionContext';
import WorkspacePage from './WorkspacePage';
import { AppClientInPopover } from './Client/Web/AppClient';
import { SignInDialog, SignInRequired } from '../../components/Auth';
import PrivacyPolicyView from '../../components/pixie/privacy-policy';
import InvitePage from './InvitePage';
import { TestPage } from './TestPage';
import { use100vh } from 'react-div-100vh';
import { useUserAndWorkspaceStore } from '../../hooks/UserAndWorkspaceStore';
import { useApolloClient } from '@apollo/client';
import { useShallow } from 'zustand/react/shallow';
import PaymentPage from './PaymentPage';

const workspaceRoutePath = '/workspace';

export default function App(): React.ReactElement {
  const [
    siteId,
    loadIdentity,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.workspaceId,
    state.loadIdentity,
  ]));
  const client = useApolloClient();

  const [contactOpen, setContactOpen] = useState(false);
  const [signInOpen, setsignInOpen] = useState(false);
  const { initialSiteId } = useContext(SiteIdContext);
  const height = use100vh();


  const [workspaceRefetchTrigger, setWorkspaceRefetchTrigger] = useState(false);
  const location = useLocation();
  // refresh app and recipe listings when navigating to workspace page
  useEffect(() => {
    if (location.pathname === workspaceRoutePath) {
      setWorkspaceRefetchTrigger(t => !t);
    }
  }, [location.pathname]);

  useEffect(() => {
    loadIdentity(client, true);
  }, []);

  return <EmailSubscriptionContextProvider>
    <Switch>
      <RouteWithFade exact path='/'>
        <LandingPage />
      </RouteWithFade>
      <RouteWithFade path={getEditorRoutePath()}>
        <EditorPage siteId={siteId} />
      </RouteWithFade>
      <Route path='/signin'>
        <SignInDialog open={true} onSignin={() => window.close()} />
      </Route>
      <Route path='/privacy-policy'>
        <PrivacyPolicyView />
      </Route>
      <Route path='/test'>
        <TestPage />
      </Route>
      <Route path='/v2/app/:flowId'>
        <Box height={`${height}px`}>
          <ChatAppWithReactRouter />
        </Box>
      </Route>
      <Route path={workspaceRoutePath}>
        <WorkspacePage refetchTrigger={workspaceRefetchTrigger} />
      </Route>
      <Route path='/payment'>
        <SignInRequired>
          <PaymentPage />
        </SignInRequired>
      </Route>
      <Route path='/invite/:code'>
        <InvitePage />
      </Route>
      <Route>
        <Box width='100%' height='100vh' display='flex' justifyContent='center' alignItems='center'>
          <Typography variant='h1'>404 NOT FOUND</Typography>
        </Box>
      </Route>
    </Switch>
    <DismissibleDialog open={contactOpen} setOpen={setContactOpen}>
      <ContactForm siteId={initialSiteId} onSubmit={() => setContactOpen(false)} />
    </DismissibleDialog>
    <SignInDialog open={signInOpen} setOpen={setsignInOpen} onSignin={() => loadIdentity(client, true)} />
  </EmailSubscriptionContextProvider>
}


function ChatAppWithReactRouter(): React.ReactElement {
  const { flowId } = useParams<{ flowId: string | undefined }>();

  return flowId
    ? <AppClientInPopover flowId={flowId} replayMessagesOnConnect initialOpen disableQuickClose />  // TODO fix this, startId should be read from storage
    : <Typography variant='h3'>Something went wrong.</Typography>
}
