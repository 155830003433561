import React, { useContext, useState } from 'react';
import { LIST_APP_TEMPLATES } from '../../../../graphql/query';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../../../../contexts/AppContext';
import { Button, CircularProgress, Dialog, Divider, IconButton, LinearProgress, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';


import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FlowNodeName } from '../FlowNodeName';
import { useEditorStore } from '../../../../hooks/EditorState';
import { CREATE_APP_TEMPLATE, DELETE_APP_TEMPLATE } from '../../../../graphql/mutation';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { PluginConstructType } from '../../../../../generated/gql/graphql';
import { ListEditView } from '../../../../components/pixie/common';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useShallow } from 'zustand/react/shallow';


function AppTemplateDeleteButton(props: {
  templateId: string,
  onDelete?: () => void,
}): React.ReactElement {
  const { setError } = useContext(AppContext);
  const [deleteAppTemplate] = useMutation(DELETE_APP_TEMPLATE);
  const [inProgress, setInProgress] = useState(false);

  return <IconButton color='error' onClick={() => {
    setInProgress(true);
    deleteAppTemplate({ variables: { templateId: props.templateId } })
      .then(() => props.onDelete?.())
      .catch(setError)
      .finally(() => setInProgress(false));
  }}>
    {inProgress
      ? <CircularProgress size='1rem' />
      : <DeleteOutlineRoundedIcon fontSize='small' />
    }
  </IconButton>

}

export function AppTemplatePanel(): React.ReactElement {
  const { setError } = useContext(AppContext);
  const appId = useEditorStore(state => state.app.id);
  const templateView = useEditorStore(state => state.editorView.templateView);
  const setTemplateView = useEditorStore(state => state.actions.setTemplateView);
  const updateDefaultEditorView = useEditorStore(state => state.graphql.updateDefaultEditorView);
  const client = useApolloClient();
  const { data, loading, refetch } = useQuery(LIST_APP_TEMPLATES, {
    variables: { flowId: appId as string },
    skip: !appId,
    onError: setError,
  });
  const allSettingsNodes = useEditorStore(useShallow(state => state.graph.nodes
    .filter(n => n.data.pluginType.dynamic?.constructType === PluginConstructType.DynamicSettings)
    .map(n => n.id)
  ));
  const [createAppTemplate] = useMutation(CREATE_APP_TEMPLATE);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState<{ id: string, settingsNodes?: string[], title?: string } | null>(null);

  if (!appId) return <Typography>Please save the app first.</Typography>

  if (loading) return <LinearProgress />

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>Templates based on this app</b></Typography>
    <Button color='secondary' variant='outlined' onClick={() => setSelectedTemplate({ id: '' })}><AddRoundedIcon /></Button>
    {data.alistAppTemplates.length == 0
      ? <Typography>No template based on this app.</Typography>
      : <TableContainer component={Paper}>
        <Table>
          {/* <TableHead>
              <TableRow>
                <TableCell>Note</TableCell>
                <TableCell align="right">Input</TableCell>
                <TableCell align="right">Output</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead> */}
          <TableBody>
            {data.alistAppTemplates.map(template => (
              <TableRow
                key={template.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '& .MuiTableCell-root': { display: 'flex', alignItems: 'center' },
                  display: 'flex',
                }}
              >
                <TableCell component="th" scope="row" sx={{ flexGrow: 1 }}>
                  <Stack direction='row' display='flex' alignItems='center'>
                    <Typography>{template.title}</Typography>
                  </Stack>
                </TableCell>
                {/* <TableCell align="right">{JSON.stringify(row.actualizedParam)}</TableCell>
                  <TableCell align="right">{JSON.stringify(row.pluginResult)}</TableCell> */}
                <TableCell align="right" sx={{ display: 'flex', alignItems: 'center' }}>
                  <AppTemplateDeleteButton templateId={template.id} onDelete={() => refetch()} />
                  <IconButton onClick={() => setSelectedTemplate(template)}><EditRoundedIcon fontSize='small' /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    }

    <Divider />
    <Typography variant='subtitle1'><b>Template-based editor</b></Typography>
    <Select
      value={templateView === null ? '' : templateView}
      displayEmpty
      onChange={e => setTemplateView(e.target.value || null)}
    >
      <MenuItem value=''>Flowchart editor</MenuItem>
      {data.alistAppTemplates.map(
        t => <MenuItem key={t.id} value={t.id}>
          {t.title}
        </MenuItem>
      )}
    </Select>
    <Button sx={{ textTransform: 'none' }} onClick={() => updateDefaultEditorView(client, templateView)}>Save as default view</Button>

    <Dialog open={Boolean(selectedTemplate)} onClose={() => setSelectedTemplate(null)} maxWidth='sm' fullWidth>
      <Stack spacing={2} p={2}>
        {saveInProgress
          ? <LinearProgress />
          : <>
            <TextField
              label='Template Name'
              value={selectedTemplate?.title || ''}
              onChange={e => setSelectedTemplate(t => t
                ? { ...t, title: e.target.value }
                : { id: '', title: e.target.value }
              )}
            />
            {allSettingsNodes.length == 0
              ? <Typography>To have customizable settings for the template, include Settings in your app.</Typography>
              : <ListEditView
                name='Customizable Settings'
                values={selectedTemplate?.settingsNodes || [] as string[]}
                itemComponent={(v, onChange) => <Select
                  value={v}
                  onChange={e => onChange(e.target.value)}
                >
                  {allSettingsNodes.map(
                    id => <MenuItem key={id} value={id}>
                      <FlowNodeName nodeId={id} variant='description' />
                    </MenuItem>
                  )}
                </Select>}
                onChange={vals => setSelectedTemplate(t => t
                  ? { ...t, settingsNodes: vals }
                  : { id: '', settingsNodes: vals }
                )}
                default={allSettingsNodes[0]}
                disableAdd={allSettingsNodes.every(n => selectedTemplate?.settingsNodes?.includes(n))}
              />
            }
            <Button
              // do not support editing existing for now
              disabled={Boolean(selectedTemplate?.id)}
              onClick={() => {
                setSaveInProgress(true);
                createAppTemplate({
                  variables: {
                    flowId: appId as string,
                    title: selectedTemplate?.title,
                    settingsNodes: selectedTemplate?.settingsNodes,
                  }
                })
                  .then(() => refetch())
                  .catch(setError)
                  .finally(() => {
                    setSaveInProgress(false);
                    setSelectedTemplate(null);
                  });
              }}>
              Save
            </Button>
          </>
        }
      </Stack>
    </Dialog>
  </Stack>
}
