import { Alert, IconButton, Link, Stack, Tooltip, Typography, debounce, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { TPluginInfo } from '../../../../generated/gql/graphql';
import { FlowNodeData } from '../../../types/GraphNode';
import { JSONSchema7 } from 'json-schema';
import ParamEditor from '../../../components/pixie/param-editor/ParamEditor';
import { DataReferences } from '../../../types/DynamicValueTypes';
import { useEditorStore } from '../../../hooks/EditorState';
import { useApolloClient } from '@apollo/client';
import { NameChangeV2 } from './NameChange';
import { FlowNodeName } from './FlowNodeName';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { ParamInputField } from '../../../components/pixie/param-editor/lexical/ParamInputField';
import { useShallow } from 'zustand/react/shallow';
import { useNodeParamValidation } from '../../../hooks/useGraphValidation';

// NOTE only plugin Id with both info and data present would be included
function createReferences(
  pluginInfoById: { [id: string]: TPluginInfo },
  nodeDataById: { [id: string]: FlowNodeData }
): DataReferences {
  const result = {};

  // Iterate over the keys of the first object
  Object.keys(pluginInfoById).forEach(key => {
    if (nodeDataById.hasOwnProperty(key)) {
      result[key] = { info: pluginInfoById[key], data: nodeDataById[key] };
    }
  });

  return result;
};

function ConfigurationPanel(props: {
  nodeId: string,
  dynamicDisabled?: boolean,
  paramsOnly?: boolean,
  p?: number,
}): React.ReactElement {
  const theme = useTheme();
  // We have expectation that nodeId exists
  const nodeData = useEditorStore(useShallow(state => state.graph.nodes.find(n => n.id === props.nodeId)?.data));
  const updateNodeData = useEditorStore(state => state.actions.graph.updateNodeData);

  const typeInfo = useEditorStore(state => state.actions.graph.getTypeInfo()[props.nodeId]);
  const dynamicPluginType = nodeData?.pluginType?.dynamic;
  const pluginConstructInfo = useEditorStore(useShallow(state => dynamicPluginType ? state.types.construct[dynamicPluginType.constructType] : undefined));

  // TODO lexical input fields for in the config panel should be forced to rerender on refresh, as param/dv data might change
  const refreshTypeInfo = useEditorStore(state => state.graphql.loadDynamicType);
  const client = useApolloClient();
  const validationErrors = useNodeParamValidation(props.nodeId);

  const onConstructorParamChange = useCallback(debounce(
    () => {
      if (typeInfo?.dynamicTypeInfoOutdated) {
        // clean node data on refreshed type info to keep it valid for the new schema
        refreshTypeInfo(client, props.nodeId, true)
      }
    },
    1000
  ), [client, props.nodeId, typeInfo?.dynamicTypeInfoOutdated]);

  // recompute dynamic type info on constructor param change
  // TODO: only recompute if the constructor param is valid
  useEffect(() => {
    onConstructorParamChange();
    return () => onConstructorParamChange.clear();
  }, [onConstructorParamChange, nodeData?.pluginType?.dynamic?.param]);

  if (!nodeData || !typeInfo) {
    return <Stack>
      <Typography variant='h6'>Node not found</Typography>
    </Stack>;
  }

  // const references = useMemo(
  //   () => {
  //     logDebug('reference changed');
  //     return createReferences(props.pluginInfoById, props.nodeDataById);
  //   },
  //   [props.pluginInfoById, props.nodeDataById],
  // )
  return <Stack spacing={2} p={props.p === undefined ? 2 : props.p}>
    {!props.paramsOnly && <>
      <Stack direction='row' justifyContent='space-between'>
        <NameChangeV2
          value={nodeData.displayName || ''}
          onChange={name => updateNodeData(nodeData.id, { displayName: name })}
          display={<FlowNodeName nodeId={nodeData.id} variant='description' />}
        />

        {pluginConstructInfo &&
          <Tooltip title="Refresh Parameters">
            <IconButton
              color={typeInfo?.dynamicTypeInfoOutdated ? 'primary' : 'default'}
              onClick={() => refreshTypeInfo(client, props.nodeId, true)}
            >
              <RefreshRoundedIcon />
            </IconButton>
          </Tooltip>
        }
      </Stack>

      {pluginConstructInfo && <ParamEditor
        uniqueId={props.nodeId} //we have the assumption the id will not change for same node
        dynamicDisabled
        schemaDef={pluginConstructInfo.parameterSchema as JSONSchema7}
        // rootName='Customization'
        value={dynamicPluginType!.param}
        onChange={(v, _) => updateNodeData(
          props.nodeId,
          {
            // NOTE this triggers construct info refetch on every onChange call
            pluginType: {
              dynamic: {
                constructType: dynamicPluginType!.constructType,
                param: v,
              }
            }
          }
        )}
      />}
    </>}
    {typeInfo?.dynamicTypeInfoOutdated
      && <Typography textAlign='center' variant='body2' sx={{ color: theme.palette.text.secondary }}>
        The parameters below might have changed due to your changes. Click <Link
          onClick={() => refreshTypeInfo(client, props.nodeId, true)}
          sx={{ cursor: 'pointer' }}
        >
          <b>here</b>
        </Link> to refresh.
      </Typography>
    }
    {typeInfo?.pluginInfo && <ParamEditor
      uniqueId={props.nodeId}
      schemaDef={typeInfo?.pluginInfo.parameterSchema as JSONSchema7}
      value={nodeData.params}
      dynamicValue={nodeData.dynamicParams}
      dynamicDisabled={props.dynamicDisabled}
      onChange={(v, dv) => updateNodeData(
        props.nodeId,
        {
          params: v,
          dynamicParams: dv,
        }
      )}
    />}

    <Stack spacing={1}>
      <Stack direction='row' display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle2'>In-Progress Status Message</Typography>
        {nodeData.inProgressMessage
          ? <IconButton color='error' onClick={() => updateNodeData(props.nodeId, { inProgressMessage: undefined })}><RemoveRoundedIcon /></IconButton>
          : <IconButton color='success' onClick={() => updateNodeData(props.nodeId, { inProgressMessage: [] })}><AddRoundedIcon /></IconButton>
        }
      </Stack>
      {nodeData.inProgressMessage && <ParamInputField
        schemaDef={{ type: 'string' }}
        initialValue={undefined}
        initialDynamicValue={nodeData.inProgressMessage}
        onChange={(v, dv) => {
          if (v) {
            updateNodeData(props.nodeId, { inProgressMessage: [v] })
          }
          else {
            if (Array.isArray(dv)) {
              updateNodeData(props.nodeId, { inProgressMessage: dv })
            }
            else if (dv === null || dv === undefined) {
              updateNodeData(props.nodeId, { inProgressMessage: [] })
            }
            else {
              updateNodeData(props.nodeId, { inProgressMessage: [dv] })
            }
          }
        }}
        resetTrigger={props.nodeId}
      />}
    </Stack>

    {validationErrors.length > 0 && <Stack spacing={1}>
      {validationErrors.map((e, i) => <Alert key={i} severity='error'><pre>{JSON.stringify(e, undefined, 2)}</pre></Alert>)}
    </Stack>}
  </Stack>;
}

export default ConfigurationPanel;
