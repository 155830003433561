// This node is for representing a constant value.
// A constant value is set by the user when they choose a value from dropdown.
// The dropdown for constant selection is only available when there's a limited set of values to choose from, e.g. enum, true/false.
// freefom values, such as string and number, are not represented by this node, instead they are represented by TextNode.
import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';

import React from 'react';
import { InlineCode } from '../../../../material-markdown';

type ConstantValueType = number | string | boolean;

export type SerializedConstantValueNode = {
  type: 'constantValue';
  version: 1;
  value: ConstantValueType;
};

export class ConstantValueNode extends DecoratorNode<React.ReactNode> {
  __value: ConstantValueType;

  static getType(): string {
    return 'constantValue';
  }

  static clone(node: ConstantValueNode): ConstantValueNode {
    return new ConstantValueNode(node.__value, node.__key);
  }

  constructor(value: ConstantValueType, key?: NodeKey) {
    super(key);
    this.__value = structuredClone(value);
  }

  exportJSON(): SerializedConstantValueNode {
    return {
      type: 'constantValue',
      version: 1,
      value: structuredClone(this.__value),
    };
  }

  static importJSON(serializedNode: SerializedConstantValueNode): ConstantValueNode {
    const node = $createConstantValueNode(serializedNode.value);
    return node;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): React.ReactNode {
    return <InlineCode>{this.__value.toString()}</InlineCode>;
  }
}


export function $createConstantValueNode(value: ConstantValueType, key?: NodeKey): ConstantValueNode {
  return new ConstantValueNode(value, key);
}

export function $isDataReferenceNode(node: LexicalNode | null | undefined): node is ConstantValueNode {
  return node instanceof ConstantValueNode;
}
