import {
  Box, Button, Card, Dialog, Grid, LinearProgress, Popover, Stack, Typography, useTheme,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';


import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { PluginCard, NodeCreationMenu, useNodeCreation } from './PluginSelectionMenu';

import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import { useEditorStore } from '../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow'
import { useGraphPosition } from '../../../hooks/useGraphPosition';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GraphNode } from '../../../types/GraphNode';
import { XYPosition } from 'reactflow';
import { UPDATE_EDITOR_SETTINGS } from '../../../graphql/mutation';
import { TRecipeInfo } from '../../../../generated/gql/graphql';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import { LIST_RECIPES } from '../../../graphql/query';
import { AppContext } from '../../../contexts/AppContext';


export function RecipeCard(props: {
  recipe: TRecipeInfo,
  onClick?: () => void,
}): React.ReactElement {
  const [elevation, setElevation] = useState(0);

  return <Card
    elevation={elevation}
    onMouseOver={() => setElevation(5)}
    onMouseLeave={() => setElevation(0)}
    sx={{
      cursor: 'pointer',
    }}
    onClick={props.onClick}
  >

    <Stack p={2} direction='row' spacing={3}>
      <Typography variant='h4'><SchemaOutlinedIcon fontSize='inherit' /></Typography>
      <Stack spacing={1}>
        <Typography variant='subtitle1'><b>{props.recipe.name}</b></Typography>
        <Typography variant='body1'>{props.recipe.description}</Typography>
      </Stack>
    </Stack>
  </Card>
}


function RecipeSelect(props: {
  onSelect?: (recipe: TRecipeInfo) => void,
}): React.ReactElement {

  const { setError } = useContext(AppContext);
  const { loading, data } = useQuery(LIST_RECIPES, {
    onError: setError,
    skip: !userId,
  })
  const recipes = data?.recipes || [];

  if (loading) {
    return <Box p={2}><LinearProgress /></Box>
  }

  if (recipes.length === 0) {
    return <Box p={2}><Typography>No recipes.</Typography></Box>
  }

  return <Stack spacing={2} p={2}>
    {recipes.map((r, idx) => <RecipeCard key={idx} recipe={r} onClick={() => props.onSelect?.(r)} />)}
  </Stack>
}


type AdditionMode = 'Function' | 'Recipe';
function getAlt(mode: AdditionMode): AdditionMode {
  return mode === 'Function' ? 'Recipe' : 'Function';
}

function GraphAdditionModal(props: {
  onAddNode?: (node: GraphNode) => void,
  open?: boolean,
  onClose?: () => void,
  position?: XYPosition,
}): React.ReactElement {
  const theme = useTheme();
  const [mode, setMode] = useState<AdditionMode>('Function');
  const client = useApolloClient();
  const pasteRecipeToApp = useEditorStore(state => state.graphql.pasteRecipeToApp);

  return <Dialog
    open={props.open}
    onClose={props.onClose}
    fullWidth
    maxWidth='sm'
    sx={{ overflow: 'hidden' }}
  >
    <Stack height='90vh'>
      <Stack>
        <Stack spacing={2} direction='row'
          onClick={() => setMode(m => getAlt(m))}
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
            userSelect: 'none',
            cursor: 'pointer',
          }}
        >
          {mode === 'Function'
            ? <MemoryRoundedIcon fontSize='large' />
            : <SchemaOutlinedIcon fontSize='large' />
          }
          <Typography><Typography variant='h5' component='span'><b>{mode}</b></Typography> | {getAlt(mode)}</Typography>
        </Stack>
      </Stack>
      <Box overflow='auto'>
        {mode === 'Function'
          ? <NodeCreationMenu
            onAddNode={n => {
              props.onAddNode?.(n);
              props.onClose?.();
            }}
            position={props.position}
          />
          : <RecipeSelect onSelect={r => {
            pasteRecipeToApp(client, r.id);
            props.onClose?.();
          }} />
        }
      </Box>
    </Stack>
  </Dialog>
}


export function AddNodeButton(props: {
  popoverAnchorEl: Element,
}): React.ReactElement {
  const [dialogOpenReason, setDialogOpenReason] = useState<'add' | 'shortcut' | null>(null);
  const { center } = useGraphPosition();
  const client = useApolloClient();

  const closeTimer = useRef(null); // Store timer reference for delayed close
  const [
    shortcuts,
    loading,
    loadEditorSettings,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.getShortcuts(),
    state.loadingStatus.editorSettings,
    state.loadEditorSettings,
  ]))
  const [
    plugins,
    pluginConstructs,
    shortcutsPopoverOpen,
    setShortcutsPopoverOpen,
  ] = useEditorStore(useShallow(state => [
    Object.values(state.types.static),
    Object.values(state.types.construct),
    state.editorView.shortcutsPopoverOpen,
    state.actions.setShortcutsPopoverOpen,
  ]));

  const [updateEditorSettings] = useMutation(UPDATE_EDITOR_SETTINGS);

  const nodeCreationProps = useMemo(() => ({
    position: center, onAddNode: () => setDialogOpenReason(null),
  }), [center])
  const {
    createPluginNode,
    createConstructedPluginNode,
  } = useNodeCreation(nodeCreationProps);

  // Function to open the popover immediately
  const hoverAddNode = useCallback(() => {
    clearTimeout(closeTimer.current); // Cancel any pending close
    setShortcutsPopoverOpen(true);
  }, []);

  // Function to close the popover with a short delay
  const leaveAddNode = useCallback(() => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }
    closeTimer.current = setTimeout(() => setShortcutsPopoverOpen(false), 200);
  }, []);

  return <>
    <Button
      variant='contained'
      color='success'
      sx={{
        display: 'flex',
        borderRadius: 20,
        p: 2,
        m: 2,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
        textTransform: 'none',
      }}
      onClick={() => setDialogOpenReason('add')}
      onMouseEnter={hoverAddNode}
      onMouseLeave={leaveAddNode}
    >
      <AddRoundedIcon fontSize='large' />
    </Button>
    <GraphAdditionModal
      open={Boolean(dialogOpenReason)}
      onClose={() => setDialogOpenReason(null)}
      onAddNode={n => {
        if (dialogOpenReason === 'shortcut') {
          updateEditorSettings({
            variables: {
              editorSettings: {
                shortcuts: [
                  ...shortcuts,
                  {
                    pluginType: n.data.pluginType.static,
                    pluginConstructorType: n.data.pluginType.dynamic?.constructType,
                  },
                ]
              }
            }
          }).then(() => loadEditorSettings(client))
        }
      }}
      position={center}
    />

    <Popover
      open={shortcutsPopoverOpen}
      anchorEl={props.popoverAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      disableAutoFocus
      disableEnforceFocus
      // this to prevent triggering mouseLeave event on the hovered button
      style={{ pointerEvents: 'none' }}
      slotProps={{
        paper: {
          sx: {
            background: 'transparent',
            boxShadow: 'none',
          }
        }
      }}
    >
      {loading
        ? <LinearProgress />
        : <Grid container
          style={{ pointerEvents: 'auto' }}
          onMouseEnter={hoverAddNode}
          onMouseLeave={leaveAddNode}
          p={2}
          spacing={2}
          alignItems='stretch'
        >
          {shortcuts.map((s, idx) =>
            <Grid item key={idx} xs={6} sm={4} md={3} lg={2}>
              {s.pluginType && <PluginCard
                key={idx}
                type={s.pluginType}
                info={plugins.find(p => p.pluginType.static === s.pluginType)}
                onClick={() => {
                  createPluginNode(s.pluginType);
                  setShortcutsPopoverOpen(false);
                }}
              />}
              {s.pluginConstructorType && <PluginCard
                key={idx}
                type={s.pluginConstructorType}
                info={pluginConstructs.find(p => p.constructType === s.pluginConstructorType)}
                onClick={() => {
                  createConstructedPluginNode(s.pluginConstructorType);
                  setShortcutsPopoverOpen(false);
                }}
              />}
            </Grid>
          )}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Button
              variant='outlined'
              color='success'
              onClick={() => setDialogOpenReason('shortcut')}
            >
              <AddRoundedIcon fontSize='large' />
              <Typography>Add Shortcut</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    </Popover>
  </>
}
