import { $getRoot, EditorState, ElementNode, LexicalNode, LineBreakNode, ParagraphNode, TextNode } from 'lexical';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { TreeView } from '@lexical/react/LexicalTreeView';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';
import { DynamicValueParam, FormattedDynamicValue, isComplex, isComposite } from '../../../../types/DynamicValueTypes';
import { ConstantValueNode } from './nodes/ConstantNode';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import DataReferenceAutoSuggestPlugin from './plugins/DataReferenceAutoSuggestPlugin';
import { JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema';
import { DynamicValueNode } from './nodes/DynamicValueNode';
import { getSchema } from '../../../../utils/dynamic-value-utils';
import { ComplexDynamicValueNode } from './nodes/ComplexDynamicValueNode';

const theme = {
  // Theme styling goes here
  //...
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

function TreeViewPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  return (
    <TreeView
      viewClassName="tree-view-output"
      treeTypeButtonClassName="debug-treetype-button"
      timeTravelPanelClassName="debug-timetravel-panel"
      timeTravelButtonClassName="debug-timetravel-button"
      timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
      timeTravelPanelButtonClassName="debug-timetravel-panel-button"
      editor={editor}
    />
  );
}


type ParamInputFieldProps = {
  schemaDef: JSONSchema7Definition,
  initialValue: any,
  initialDynamicValue?: DynamicValueParam,
  onChange: (value: any, dynamicValue: DynamicValueParam | undefined) => void,
  dynmaicOnly?: boolean,
  resetTrigger?: any,
  debug?: boolean,
}


function ParamInputFieldInternal(props: ParamInputFieldProps): React.ReactElement {

  const [editor] = useLexicalComposerContext();

  // NOTE: this effect will automatically trigger onChange callback for modifying internal editor state
  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      // both undefined and null are considered as no initial dynamic value
      if (props.initialDynamicValue != undefined && !isComposite(props.initialDynamicValue)) {
        let currentParagraph = new ParagraphNode();
        const formattedDv = Array.isArray(props.initialDynamicValue) ? props.initialDynamicValue : [props.initialDynamicValue];
        for (const val of formattedDv) {
          if (typeof val === 'string') {
            let line = '';
            for (const char of val) {
              if (char === '\n') {
                currentParagraph.append(new TextNode(line));
                root.append(currentParagraph);
                line = '';
                currentParagraph = new ParagraphNode();
              } else {
                line += char;
              }
            }
            if (line) {
              currentParagraph.append(new TextNode(line));
            }
          }
          else if (isComplex(val)) {
            currentParagraph.append(new ComplexDynamicValueNode(val));
          }
          else {
            currentParagraph.append(new DynamicValueNode(val));
          }
        }
        if (currentParagraph.getChildren().length > 0) {
          root.append(currentParagraph);
        }
      }
      else if (props.initialValue !== undefined) {
        let currentParagraph = new ParagraphNode();
        switch (typeof props.initialValue) {
          case 'string':
            if (props.initialValue === '') {
              currentParagraph.append(new ConstantValueNode(''));
              break;
            }

            let line = '';
            for (const char of props.initialValue) {
              if (char === '\n') {
                currentParagraph.append(new TextNode(line));
                root.append(currentParagraph);
                line = '';
                currentParagraph = new ParagraphNode();
              } else {
                line += char;
              }
            }
            currentParagraph.append(new TextNode(line));
            break;
          case 'number':
            currentParagraph.append(new TextNode(props.initialValue.toString()));
            break;
          case 'boolean':
            currentParagraph.append(new TextNode(props.initialValue.toString()));
            //currentParagraph.append(new ConstantValueNode(props.initialValue));
            break;
          default:
            currentParagraph.append(new TextNode(JSON.stringify(props.initialValue, undefined, 2)));
            break;
        }
        if (currentParagraph.getChildren().length > 0) {
          root.append(currentParagraph);
        }
      }
    });
  }, [props.resetTrigger]);

  const updateExternalStates = (editorState: EditorState) => {
    editorState.read(() => {
      const root = $getRoot();
      // This is expected to be undefined when there's any data reference node
      let maybeTextOrConstant = tryGetConstantOrText(root);

      // remove trailing newline
      if (typeof maybeTextOrConstant === 'string' && maybeTextOrConstant.endsWith('\n')) {
        maybeTextOrConstant = maybeTextOrConstant.slice(0, -1);
      }

      // This early return is necessary before tryGetStaticValue
      // since an empty tree would return "" for text, and in-turn results in "" value when target type is string
      if (maybeTextOrConstant === "" && root.getAllTextNodes().length === 0) {
        props.onChange(undefined, undefined);
        return;
      }

      const maybeValue = tryGetStaticValue(maybeTextOrConstant, props.schemaDef);
      if (maybeValue !== undefined) {
        props.onChange(maybeValue, undefined);
      }
      else {
        const nodes = root.getChildren();
        const dv = nodes.flatMap(lexicalNodeToDynamicValue);
        // Squash consecutive strings in dv
        for (let i = 0; i < dv.length - 1; i++) {
          if (typeof dv[i] === 'string' && typeof dv[i + 1] === 'string') {
            dv[i] = (dv[i] as string) + (dv[i + 1] as string);
            dv.splice(i + 1, 1);
            i--; // Adjust index to check the new dv[i] with the next element
          }
        }

        // remove trailing whitespace
        const last = dv[dv.length - 1]
        if (typeof last === 'string') {
          dv[dv.length - 1] = last.trimEnd();
          if (dv[dv.length - 1] === '') {
            dv.pop();
          }
        }

        if (dv.length === 0) {
          props.onChange(undefined, undefined);
        } else {
          props.onChange(undefined, dv);
        }
      }
    });
  }

  useEffect(() => {
    updateExternalStates(editor.getEditorState());
  }, [JSON.stringify(props.schemaDef), editor]);

  return <>
    <RichTextPlugin
      contentEditable={<ContentEditable />}
      placeholder={<div>Enter some text...</div>}
      ErrorBoundary={LexicalErrorBoundary}
    />
    <DataReferenceAutoSuggestPlugin />
    <HistoryPlugin />
    <AutoFocusPlugin />
    {props.debug && <TreeViewPlugin />}
    <OnChangePlugin
      ignoreHistoryMergeTagChange
      ignoreSelectionChange
      onChange={updateExternalStates}
    />
  </>
}


// NOTE: source of truth value is in editor state.
export function ParamInputField(props: ParamInputFieldProps): React.ReactElement {
  const initialConfig = {
    namespace: 'GoPixie.ParamInputField',
    theme,
    onError,
    nodes: [ConstantValueNode, DynamicValueNode, ComplexDynamicValueNode],
  };

  return <LexicalComposer initialConfig={initialConfig}>
    <ParamInputFieldInternal {...props} />
  </LexicalComposer>;
}


function lexicalNodeToDynamicValue(node: LexicalNode): FormattedDynamicValue {
  if (node instanceof DynamicValueNode) {
    return [node.__value];
  }
  else if (node instanceof ComplexDynamicValueNode) {
    return [node.__value];
  }
  else if (node instanceof ConstantValueNode) {
    return [node.__value.toString()];
  }
  else if (node instanceof TextNode) {
    return [node.getTextContent()];
  }
  else if (node instanceof LineBreakNode) {
    return ['\n'];
  }
  else if (node instanceof ElementNode) {
    let children = node.getChildren().flatMap(lexicalNodeToDynamicValue);
    if (node instanceof ParagraphNode) {
      children.push('\n');
    }
    return children;
  }
}

// returns undefined when any node is not a constant or text node or container element node
// i.e. if there's any data reference node, return undefined
function tryGetConstantOrText(node: LexicalNode): string | ConstantValueNode | undefined {
  if (node instanceof ConstantValueNode) {
    return node;
  }
  else if (node instanceof DynamicValueNode) {
    return undefined;
  }
  else if (node instanceof ComplexDynamicValueNode) {
    return undefined;
  }
  else if (node instanceof TextNode) {
    return node.getTextContent();
  }
  else if (node instanceof LineBreakNode) {
    return '\n';
  }
  else if (node instanceof ElementNode) {
    let children = node.getChildren().map(tryGetConstantOrText);
    if (children.some(child => child === undefined)) {
      return undefined;
    }
    const constants = children.filter(child => child instanceof ConstantValueNode) as ConstantValueNode[];
    const subtexts = children.filter(child => typeof child === 'string') as string[];
    if (constants.length === 1 && subtexts.join('').trim() === '') {
      return constants[0];
    }
    else {
      const texts = children.map(
        textOrConstant => typeof textOrConstant === 'string'
          ? textOrConstant :
          textOrConstant.__value.toString()
      );
      if (node instanceof ParagraphNode) {
        texts.push('\n');
      }
      return texts.join('');
    }
  }
  // return undefined if any other type of node
  return undefined;
}

function tryGetStaticValue(maybeConstantOrText: string | ConstantValueNode | undefined, schemaDef: JSONSchema7Definition): any {
  if (maybeConstantOrText === undefined) {
    return undefined;
  }
  const schema = getSchema(schemaDef);
  // return first match if the schema has anyOf
  for (const subschemaDef of schema?.anyOf || []) {
    const maybeVal = tryGetStaticValue(maybeConstantOrText, subschemaDef);
    if (maybeVal !== undefined) {
      return maybeVal;
    }
  }
  if (!schema?.type) {
    return maybeConstantOrText instanceof ConstantValueNode ? maybeConstantOrText.__value : maybeConstantOrText;
  }
  return tryGetStaticValueForType(maybeConstantOrText, schema.type);
}


function tryGetStaticValueForType(constantOrText: string | ConstantValueNode, type: JSONSchema7TypeName | JSONSchema7TypeName[]): any {
  if (Array.isArray(type)) {
    for (const subType of type) {
      const maybeVal = tryGetStaticValueForType(constantOrText, subType);
      if (maybeVal !== undefined) {
        return maybeVal;
      }
    }
    return undefined;
  }

  if (type === 'null') {
    return null;
  }

  if (constantOrText instanceof ConstantValueNode) {
    if (type === 'integer' && Number.isInteger(constantOrText.__value)) {
      return constantOrText.__value;
    }
    if (typeof constantOrText.__value === type) {
      return constantOrText.__value;
    }
    return undefined;
  }
  else {
    switch (type) {
      case 'string':
        return constantOrText;
      case 'integer': {
        const maybeInt = parseInt(constantOrText);
        return Number.isNaN(maybeInt) ? undefined : maybeInt;
      }
      case 'number': {
        const maybeInt = parseInt(constantOrText);
        if (!Number.isNaN(maybeInt)) {
          return maybeInt;
        }
        const maybeFloat = parseFloat(constantOrText);
        return Number.isNaN(maybeFloat) ? undefined : maybeFloat;
      }
      case 'boolean': {
        const booleanMap: { [key: string]: boolean } = {
          'true': true,
          'false': false,
          '1': true,
          '0': false,
          'yes': true,
          'no': false,
        };
        return booleanMap[constantOrText.toLowerCase()];
      }
      default:
        return undefined;
    }
  }
}
