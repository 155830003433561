import { Box, Button, Popover, Stack, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton, Typography } from "@mui/material";
import { FlowNodeData } from '../../../../types/GraphNode';
import { LabeledDataPanel } from './LabeledDataPanel';
import { LinkedAccountPanel } from './LinkedAccountPanel';
import { LaunchChannelsPanel } from './LaunchChannelsPanel';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { AppSettingsPanel } from './AppSettingsPanel';
import { TPluginInfo } from '../../../../../generated/gql/graphql';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { SettingsMenuOption } from '../../../../types/SettingsMenuOption';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { KnowledgeBasesPanel } from './KnowledgeBasesPanel';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import { AppAppearancePanel } from './AppAppearancePanel';
import { Icon } from '@iconify/react';
import { AppTemplatePanel } from './AppTemplatePanel';

export function SettingsMenu(): React.ReactElement {

  const theme = useTheme();
  const { selected, select } = useEditorStore(
    useShallow(state => ({
      selected: state.settingsMenu.selected,
      select: state.actions.setSelectedSettingMenuOption,
    }))
  )
  const editorMode = useEditorStore(state => state.mode);
  const menuOptions = useMemo(() => {
    if (editorMode === 'recipe') {
      return [
        SettingsMenuOption.KnowledgeBases,
        SettingsMenuOption.LinkedAccounts,
      ]
    }
    return Object.values(SettingsMenuOption);
  }, [editorMode]);

  return <Stack direction='row'>
    <Box sx={{
      background: theme.palette.background.paper,
      boxShadow: '3px 0 10px -10px #000',
      borderRight: `1px solid ${theme.palette.divider}`,
    }}>
      <Stack p={1} spacing={1}>
        {menuOptions.map(
          v => <SettingsMenuButton
            key={v}
            option={v}
            selected={v == selected}
            onChange={(selected) => selected ? select(v) : select(null)} />
        )}
      </Stack>
    </Box>
    {/* <Card>
          settings detail
        </Card> */}
  </Stack>;
}

export function SettingsPanel(props: {
  siteId: string,
}): React.ReactElement {

  const {
    selectedSettingMenuOption,
    setSelectedSettingMenuOption,
  } = useEditorStore(
    useShallow(state => ({
      selectedSettingMenuOption: state.settingsMenu.selected,
      setSelectedSettingMenuOption: state.actions.setSelectedSettingMenuOption,
    }))
  )

  const settingsContent = useMemo(() => {
    switch (selectedSettingMenuOption) {
      case SettingsMenuOption.AppSettings:
        return <AppSettingsPanel />
      case SettingsMenuOption.KnowledgeBases:
        return <KnowledgeBasesPanel />
      // // case SettingsMenuOption.RunHistory:
      case SettingsMenuOption.LabeledResults:
        return <LabeledDataPanel />;
      case SettingsMenuOption.LinkedAccounts:
        return <LinkedAccountPanel siteId={props.siteId} />;
      case SettingsMenuOption.LaunchChannels:
        return <LaunchChannelsPanel siteId={props.siteId} />
      case SettingsMenuOption.AppAppearance:
        return <AppAppearancePanel />
      case SettingsMenuOption.AppTemplate:
        return <AppTemplatePanel />;
      default: return <Typography>No settings</Typography>;
    }
  }, [props]);

  return <Stack spacing={2} p={2}>
    <Stack direction='row' spacing={1} display='flex' justifyContent='space-between' alignItems='center'>
      <Typography variant='h6'>{selectedSettingMenuOption}</Typography>
      <IconButton onClick={() => setSelectedSettingMenuOption(null)}><CloseRoundedIcon fontSize='small' /></IconButton>
    </Stack>
    {settingsContent}
  </Stack>;
}


function getSettingsMenuIcon(option: SettingsMenuOption): React.ReactElement {
  switch (option) {
    case SettingsMenuOption.AppSettings: return <SettingsOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.RunHistory: return <HistoryOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.KnowledgeBases: return <ArticleOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.LabeledResults: return <BookmarksOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.LinkedAccounts: return <AccountCircleOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.LaunchChannels: return <LaunchRoundedIcon fontSize='inherit' />;
    case SettingsMenuOption.AppAppearance: return <ColorLensOutlinedIcon fontSize='inherit' />;
    case SettingsMenuOption.AppTemplate: return <Icon icon='carbon:prompt-template' fontSize='inherit' />;
  }
}


export function SettingsMenuButton(props: {
  option: SettingsMenuOption,
  selected?: boolean,
  onChange?: (selected: boolean) => void,
}): React.ReactElement {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const hovered = Boolean(anchorEl);
  // NOTE: for some reason returning different obj based on hovered flag doesn't work for hovering
  // even though the returned value is correct.
  const coloring = props.selected
    ? {
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
      }
    }
    : {
      color: 'grey.300',
      '&:hover': {
        backgroundColor: 'secondary.light',
        color: 'secondary.contrastText',
      }
    };

  return <>
    <Button
      size='large'
      onClick={() => props.onChange?.(!props.selected)}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{
        borderRadius: 5,
        fontSize: 32,
        p: 2,
        ...coloring,
      }}
    >
      {getSettingsMenuIcon(props.option)}
    </Button>

    <Popover
      sx={{
        pointerEvents: 'none',
        marginLeft: '15px',
        borderRadius: 20,
      }}
      open={hovered}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      slotProps={{
        paper: {
          sx: {
            p: 1,
            borderRadius: 25,
            backgroundColor: theme.palette.info.dark,
            color: theme.palette.info.contrastText,
          }
        }
      }}
    >
      <Typography pl={2} pr={2}><b>{props.option}</b></Typography>
    </Popover >
  </>
}
