import React, { useState } from 'react';
import { Stack, Box, Typography, Dialog, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { DynamicValue } from '../../../types/DynamicValueTypes';
import { DataRefAutocomplete } from '../DataRefPopover';
import { getSchemaDef } from '../../../utils/dynamic-value-utils';
import { useEditorStore } from '../../../hooks/EditorState';
import { getSchema } from '../../../utils/dynamic-value-utils';


export default function JsonSchemaRefDialog(props: {
  onChange: (schema: JSONSchema7) => void,
}): React.ReactElement {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [typeRef, setTypeRef] = useState<DynamicValue | null>(null);
  const typeInfos = useEditorStore(state => state.actions.graph.getTypeInfo());

  const [dataRefType, setDataRefType] = useState<'parameter' | 'result'>('parameter');

  return <Stack spacing={1}>
    <Button
      variant='outlined'
      size='small'
      sx={{ textTransform: 'none' }}
      onClick={() => {
        setDialogOpen(true)
      }}
    >
      <Typography variant='body2'>Use Reference</Typography>
    </Button>
    <Dialog open={dialogOpen} onClose={() => {
      setDialogOpen(false);
      setTypeRef(null);
    }} maxWidth='md' fullWidth>
      <Stack p={2} spacing={2}>
        <Box display='flex' justifyContent='center'>
          <ToggleButtonGroup exclusive onChange={(e, v) => setDataRefType(v)}>
            <ToggleButton value='parameter' selected={dataRefType === 'parameter'}>Parameter</ToggleButton>
            <ToggleButton value='result' selected={dataRefType === 'result'}>Result</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <DataRefAutocomplete dynamicValue={typeRef} dataRefType={dataRefType} onChange={typeRef => {
          const info = typeInfos[typeRef.reference]?.pluginInfo;
          const resultSchema = dataRefType === 'parameter' ? info?.parameterSchema : info?.dataResultSchema;
          props.onChange(getSchema(getSchemaDef(resultSchema, typeRef.access_path)) || { type: 'null' });
          setTypeRef(typeRef);
        }} />
      </Stack>
    </Dialog>

  </Stack>
}
