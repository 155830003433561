import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';

import { DynamicValue } from '../../../../../types/DynamicValueTypes';
import React from 'react';
import { DynamicValueDisplay } from '../../../DynamicValueDisplay';


const NODE_TYPE = 'dynamicValue';


export type SerializedDynamicValueNode = {
  type: typeof NODE_TYPE;
  version: 1;
  value: DynamicValue;
};

export class DynamicValueNode extends DecoratorNode<React.ReactNode> {
  __value: DynamicValue;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: DynamicValueNode): DynamicValueNode {
    return new DynamicValueNode(node.__value, node.__key);
  }

  constructor(dynamicValue: DynamicValue, key?: NodeKey) {
    super(key);
    this.__value = structuredClone(dynamicValue);
  }

  exportJSON(): SerializedDynamicValueNode {
    return {
      type: NODE_TYPE,
      version: 1,
      value: structuredClone(this.__value),
    };
  }

  static importJSON(serializedNode: SerializedDynamicValueNode): DynamicValueNode {
    const node = $createDynamicValueNode(serializedNode.value);
    return node;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): React.ReactNode {
    return <DynamicValueDisplay dynamicValue={this.__value} />;
  }
}


export function $createDynamicValueNode(value: DynamicValue, key?: NodeKey): DynamicValueNode {
  return new DynamicValueNode(value, key);
}

export function $isDynamicValueNode(node: LexicalNode | null | undefined): node is DynamicValueNode {
  return node instanceof DynamicValueNode;
}
