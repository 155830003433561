import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';

import { ComplexDynamicValue } from '../../../../../types/DynamicValueTypes';
import React from 'react';
import { DynamicValueDisplay } from '../../../DynamicValueDisplay';
import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import JsonView from 'react18-json-view';


const NODE_TYPE = 'complexDynamicValue';


export type SerializedComplexDynamicValueNode = {
  type: typeof NODE_TYPE;
  version: 1;
  value: ComplexDynamicValue;
};

export class ComplexDynamicValueNode extends DecoratorNode<React.ReactNode> {
  __value: ComplexDynamicValue;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: ComplexDynamicValueNode): ComplexDynamicValueNode {
    return new ComplexDynamicValueNode(node.__value, node.__key);
  }

  constructor(complexDynamicValue: ComplexDynamicValue, key?: NodeKey) {
    super(key);
    this.__value = structuredClone(complexDynamicValue);
  }

  exportJSON(): SerializedComplexDynamicValueNode {
    return {
      type: NODE_TYPE,
      version: 1,
      value: structuredClone(this.__value),
    };
  }

  static importJSON(serializedNode: SerializedComplexDynamicValueNode): ComplexDynamicValueNode {
    const node = $createComplexDynamicValueNode(serializedNode.value);
    return node;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): React.ReactNode {
    return <Card>
      <Stack direction='row' p={1} display="inline-block" divider={<Divider orientation="vertical" flexItem />}>
        <Typography>{this.__value.precedence === 'latest' ? "Latest in " : "First available in "}</Typography>
        {this.__value.values.map((dv, idx) =>
          <DynamicValueDisplay key={idx} dynamicValue={dv} />
        )}
        {![null, undefined].includes(this.__value.default) && <Typography variant='subtitle2' pl={1}>
          Default when missing: <JsonView
            src={this.__value.default}
            collapseStringsAfterLength={10}
            collapsed={1}
          />
        </Typography>}
      </Stack>
    </Card>;
  }
}


export function $createComplexDynamicValueNode(value: ComplexDynamicValue, key?: NodeKey): ComplexDynamicValueNode {
  return new ComplexDynamicValueNode(value, key);
}

export function $isDynamicValueNode(node: LexicalNode | null | undefined): node is ComplexDynamicValueNode {
  return node instanceof ComplexDynamicValueNode;
}
