import React, { useState } from "react";
import { useTheme, Stack, Box, IconButton, Button, Dialog, Typography } from "@mui/material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Icon } from "@iconify/react";
import JsonView from "react18-json-view";
import 'react18-json-view/src/style.css';

export function ArrayInputField<T>(props: {
  value: T[] | null | undefined,
  onChange: (value: T[] | undefined) => void,
  renderItemEditor: (itemValue: T | undefined, onChange: (v: T) => void, index: number) => React.ReactElement,
  defaultItemValue: (idx: number) => T,
  renderItem?: (itemValue: T, index: number) => React.ReactElement,
}): React.ReactElement {
  const theme = useTheme();
  const values: any[] = Array.isArray(props.value) ? props.value : [];

  const [editingItem, setEditingItem] = useState<number | null>(null);

  const updateItem = (val, idx) => {
    const newVals = [...values];
    newVals[idx] = val;
    props.onChange(newVals);
  }

  return <Stack spacing={1}>
    {values.map((v, idx) => <Box
      key={idx}
      p={1}
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      }}
    >
      {editingItem === idx
        ? editingItem !== null && <Stack spacing={1}>
          <Box width='100%' display='flex' justifyContent='flex-end'>
            <IconButton color='success' onClick={() => setEditingItem(null)}>
              <CheckRoundedIcon />
            </IconButton>
          </Box>

          {props.renderItemEditor(values[editingItem], (v) => updateItem(v, editingItem), editingItem)}
        </Stack>
        : <Stack
          direction='row'
          key={idx}
          spacing={2}
          justifyContent='space-between'
          alignItems='center'
        >
          <Box flexGrow={1}>
            {props.renderItem?.(v, idx) ||
              <JsonView
                src={v}
                enableClipboard={false}
                collapsed={1}
              />
            }
          </Box>
          <Stack direction='row' fontSize='12px'>
            <IconButton size="small" onClick={() => setEditingItem(idx)}><Icon icon='bx:edit' /></IconButton>
            <IconButton size="small" color='error' onClick={() => {
              setEditingItem(null);
              props.onChange(values.filter((_, i) => i !== idx));
            }}><DeleteOutlineRoundedIcon fontSize="inherit" /></IconButton>
          </Stack>
        </Stack>
      }
    </Box>)}

    <Button variant="outlined" color='success' size="small" onClick={() => {
      const newVals = [...values, props.defaultItemValue(values.length)];
      setEditingItem(values.length);
      props.onChange(newVals);
    }}>
      <AddRoundedIcon />
    </Button>
  </Stack>
}
