import React, { useMemo } from 'react';
import { FormControlLabel, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { JSONSchema7, JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema';
import { FlowParamInputType } from '../../../../generated/gql/graphql';
import { useUserAndWorkspaceStore } from '../../../hooks/UserAndWorkspaceStore';
import { getSchema } from '../../../utils/dynamic-value-utils';
import { toPydanticData, toStrawberryData } from '../../../utils/pydantic';
import { JSONInputField } from '../common';
import { ArrayInputField } from './DEPRECATED/ArrayInputField';
import DatasetSelect from '../../input/datasetSelect';
import FileSelect from '../../input/fileSelect';
import KnowledgeBaseSelect from '../../input/KnowledgeBaseSelect';
import { ParamEditor } from './ParamEditor';
import Ajv from 'ajv';

// type SpecializedJSONSchema7 = JSONSchema7 & { inputType?: FlowParamInputType };

// function isSpecializedSchema(schemaDef: JSONSchema7Definition): schemaDef is SpecializedJSONSchema7 {
//   if (!getSchema(schemaDef)) {
//     return false;
//   }
//   return (schemaDef as SpecializedJSONSchema7).inputType !== undefined;
// }

function emptyToString(val: any): any {
  if (val === null || val === undefined) return '';
  return val;
}

function undefinedToDefault(val: any, schemaDef: JSONSchema7Definition | undefined): any {
  if (val === undefined) {
    return getDefaultValue(schemaDef);
  }
  return val;
}


function isPropertyOptional(required: string[] | undefined, propertyName: string, propertySchemaDef: JSONSchema7Definition): boolean {
  return Boolean(getSchema(propertySchemaDef)?.default || !required?.includes(propertyName))
}


function getDefaultValue(schemaDef: JSONSchema7Definition | undefined): any {
  const schema = getSchema(schemaDef);
  if (!schema) return {}
  if (schema.default !== undefined) return schema.default;
  const targetType = schema.type || schema.anyOf?.[0];
  if (!targetType) return {};
  switch (targetType) {
    case true: return {};
    case "string": return "";
    case "number": return 0;
    case "boolean": return false;
    case "object": return schema.properties
      ? Object.entries(schema.properties)
        .reduce((ret, [k, t]) => {
          if (isPropertyOptional(schema.required, k, t)) return ret;
          return {
            ...ret,
            [k]: getDefaultValue(t)
          }
        }, {})
      : {};
    case "integer": return 0;
    case "array": return [];
    case "null": return null;
    default:
      if (Array.isArray(targetType)) {
        if (targetType.length == 0) return {};
        return getDefaultValue({ "type": targetType[0] });
      }
      return getDefaultValue(targetType);
  }
}


// function SpecializedParamInputField(props: {
//   schema: JSONSchema7,
//   inputType: FlowParamInputType,
//   value?: any,
//   onChange: (val?: any) => void,
//   resetTrigger?: any,
// }): React.ReactElement {
//   const siteId = useUserAndWorkspaceStore(state => state.workspaceId);

//   if (props.schema.type == 'array') {
//     return <ArrayInputField
//       itemSchema={{ ...getSchema(props.schema.items), inputType: props.inputType }}
//       value={(props.value || []) as any[]}
//       onChange={props.onChange}
//     // renderItem={item => <Card sx={{ p: 1 }}>
//     //   <SpecialParamInput
//     //     inputType={props.inputType}
//     //     value={v}
//     //     onChange={onChange}
//     //   />
//     // </Card>}
//     />

//   }
//   switch (props.inputType) {
//     case FlowParamInputType.KnowledgeBase:
//       return <KnowledgeBaseSelect
//         value={toStrawberryData(props.value)}
//         onChange={v => props.onChange(toPydanticData(v))}
//       />
//     case FlowParamInputType.JsonSchema:
//       return <>
//         <JsonSchemaEditor
//           value={props.value || {}}
//           onChange={props.onChange}
//           resetTrigger={props.resetTrigger}
//         />
//       </>

//     case FlowParamInputType.Dataset:
//       return <DatasetSelect
//         datasetId={props.value}
//         siteId={siteId}
//         onChange={props.onChange}
//       />
//     case FlowParamInputType.File:
//       return <FileSelect
//         fileId={props.value}
//         onChange={props.onChange}
//       />
//     default:
//       return <JSONInputField
//         value={props.value}
//         onChange={props.onChange}
//       />
//   }
// }


export function StaticParamInputField(props: {
  schemaDef: JSONSchema7Definition,
  value: any,
  onChange: (value: any) => void,
  resetTrigger?: any,
}): React.ReactElement {
  const schema = getSchema(props.schemaDef);
  const valueOrDefault = undefinedToDefault(props.value, schema);

  const fallbackInputField = <JSONInputField
    value={valueOrDefault}
    onChange={v => props.onChange(v)}
  />

  if (!schema?.type) {
    return fallbackInputField;
  }

  if (Array.isArray(schema.type)) {
    return <StaticParamInputField
      {...props}
      schemaDef={{ type: schema.type[0] }}
    />
  }

  // if (isSpecializedSchema(props.schema))
  //   return <SpecializedParamInputField
  //     schema={props.schema}
  //     inputType={props.schema.inputType}
  //     value={valueOrDefault}
  //     onChange={props.onChange}
  //     resetTrigger={props.resetTrigger}
  //   />

  if (schema.enum) {
    return <Select
      value={emptyToString(valueOrDefault)}
      onChange={e => props.onChange(e.target.value)}
    >
      {schema.enum.map((v, idx) => <MenuItem key={idx} value={emptyToString(v)}>{v?.toString()}</MenuItem>)}
    </Select>
  }
  switch (schema.type) {
    case "boolean":
      return <FormControlLabel
        control={<Switch checked={Boolean(valueOrDefault)} onChange={(_, checked) => props.onChange(checked)} />}
        label={Boolean(valueOrDefault) ? 'True' : 'False'}
      />
    case "number":
    case "integer":
      return <TextField
        type='number'
        value={emptyToString(valueOrDefault)}
        onChange={e => props.onChange(+e.target.value)}
      />
    case "string":
      return <TextField
        multiline
        maxRows={10}
        value={emptyToString(valueOrDefault)}
        onChange={e => props.onChange(e.target.value)}
      />
    // render the fallback input field for complex types
    case 'object':
    case "array":
    case 'null':
    default:
      return fallbackInputField;
  }
}



// function addInputType(schema: JSONSchema7, inputType: FlowParamInputType): SpecializedJSONSchema7 {
//   const modified = structuredClone(schema) as SpecializedJSONSchema7;
//   modified.inputType = inputType;
//   if (modified.anyOf) {
//     modified.anyOf = modified.anyOf.map(s => typeof s === 'boolean' ? s : addInputType(s, inputType));
//   }
//   if (modified.items) {
//     if (Array.isArray(modified.items)) {
//       modified.items = modified.items.map(s => typeof s === 'boolean' ? s : addInputType(s, inputType));
//     }
//     else if (typeof modified.items === 'object') {
//       modified.items = addInputType(modified.items, inputType);
//     }
//   }
//   return modified
// }


// function getMetaSchema7(): JSONSchema7 {
//   const ajv = new Ajv({ meta: true });
//   const metaSchema7 = ajv.getSchema("http://json-schema.org/draft-07/schema#")?.schema;;
//   return addInputType(metaSchema7 as JSONSchema7, FlowParamInputType.JsonSchema);
// }



// export function JsonSchemaEditor(props: {
//   value: JSONSchema7,
//   onChange: (value: JSONSchema7) => void,
//   resetTrigger?: any,
// }): React.ReactElement {
//   const metaSchema = {
//     type: "object",
//     properties: {
//       type: {
//         type: "string",
//         enum: ["object", "array", "string", "number", "integer", "boolean", "null"],
//       },
//       title: { type: "string" },
//       description: { type: "string" },
//       default: {
//         type: 'string'
//       },
//       enum: {
//         type: "array",
//         items: true,
//         minItems: 1,
//         uniqueItems: true,
//       },
//       items: {
//         inputType: FlowParamInputType.JsonSchema,
//         default: {
//           type: "string",
//         }
//       },
//       properties: {
//         type: "object",
//         additionalProperties: {
//           type: "object",
//           properties: {
//             inputType: FlowParamInputType.JsonSchema,
//             default: {
//               type: "string",
//             }
//           }
//         }
//       },
//       required: {
//         type: "array",
//         items: {
//           type: "string",
//         }
//       }
//     },
//     required: ["type"],
//   };

//   // return <pre>{JSON.stringify(metaSchema, undefined, 2)}</pre>
//   return <StaticParamEditor
//     resetTrigger={props.resetTrigger}
//     value={props.value}
//     onChange={props.onChange}
//     schemaDef={metaSchema as unknown as SpecializedJSONSchema7}
//   />;
// }
