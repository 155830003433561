import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import { useEditorStore } from '../../../../hooks/EditorState';
import { MuiColorInput } from 'mui-color-input';
import { useApolloClient, useMutation } from '@apollo/client';
import { SET_STYLING } from '../../../../graphql/mutation';
import { AppContext } from '../../../../contexts/AppContext';
import { isEqual } from 'lodash';
import { removeTypename } from '../../../../utils/removeTypename';
import { JSONInputField } from '../../../../components/pixie/common';
import { TIcon } from '../../../../../generated/gql/graphql';
import { useShallow } from 'zustand/react/shallow';
import { defaultFont, defaultLayout, defaultPalette, useClientStore } from '../../../../hooks/ClientState';


export function AppAppearancePanel(): React.ReactElement {
  const appId = useEditorStore(state => state.app.id);
  const [tempPalette, setTempPalette] = React.useState(defaultPalette);
  const [tempFont, setTempFont] = React.useState(defaultFont);
  const [tempLayout, setTempLayout] = React.useState(defaultLayout);
  const [tempOpenAfter, setTempOpenAfter] = React.useState<number | null>(null);
  const [tempIcon, setTempIcon] = React.useState<TIcon | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [
    styleLoaded,
    palette,
    font,
    layout,
    openAfter,
    icon,
    isDefaultPalette,
    isDefaultFont,
    isDefaultLayout,
    loadStylingCustomizations,
  ] = useClientStore(useShallow(state => [
    state.stylingLoaded,
    state.getPalatte(),
    state.getFont(),
    state.getLayout(),
    state.openAfter,
    state.icon,
    state.isDefaultPalette(),
    state.isDefaultFont(),
    state.isDefaultLayout(),
    state.loadStylingCustomizations,
  ]));
  const apolloCLient = useApolloClient();

  const refetch = useCallback(() => {
    setLoading(true);
    loadStylingCustomizations(apolloCLient).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setTempPalette(palette);
    setTempFont(font);
    setTempLayout(layout);
    setTempOpenAfter(openAfter);
    setTempIcon(icon);
  }, [palette, font, layout, openAfter, icon]);

  const paletteHasChanged = !isEqual(palette, tempPalette);
  const fontHasChanged = !isEqual(font, tempFont);
  const [inProgress, setInProgress] = React.useState(false);
  const [setStyling] = useMutation(SET_STYLING);
  const { setError, setSuccessMessage } = useContext(AppContext);

  const paletteResetButton = <Button
    variant='outlined'
    onClick={() => {
      if (isDefaultPalette) {
        setTempPalette(defaultPalette);
      }
      else {
        setInProgress(true);
        setStyling({
          variables: {
            flowId: appId,
            palette: defaultPalette,
          }
        }).then(() => {
          refetch();
          setSuccessMessage('Palette reset to default');
        }).catch(err => {
          setError(err.message);
        }).finally(() => {
          setInProgress(false)
        });
      }
    }}
  >
    Reset
  </Button>;

  const fontResetButton = <Button
    variant='outlined'
    onClick={() => {
      if (isDefaultFont) {
        setTempFont(defaultFont);
      }
      else {
        setInProgress(true);
        setStyling({
          variables: {
            flowId: appId,
            font: defaultFont,
          }
        }).then(() => {
          refetch();
          setSuccessMessage('Font reset to default');
        }).catch(err => {
          setError(err.message);
        }).finally(() => {
          setInProgress(false)
        });
      }
    }}
  >
    Reset
  </Button>;

  const layoutResetButton = <Button
    variant='outlined'
    onClick={() => {
      if (isDefaultLayout) {
        setTempLayout(defaultLayout);
      }
      else {
        setInProgress(true);
        setStyling({
          variables: {
            flowId: appId,
            layout: defaultLayout,
          }
        }).then(() => {
          refetch();
          setSuccessMessage('Layout reset to default');
        }).catch(err => {
          setError(err.message);
        }).finally(() => {
          setInProgress(false)
        });
      }
    }}
  >
    Reset
  </Button>;

  const getActionButton = (hasChanged, variables, resetButton, isDefault) => {
    if (inProgress || loading || !styleLoaded) {
      return <CircularProgress size={36} />;
    }
    else if (hasChanged) {
      return <Stack direction='row' spacing={1}>
        <Button
          variant='contained'
          onClick={() => {
            setInProgress(true);
            setStyling({
              variables
            }).then(() => {
              refetch();
              setSuccessMessage('Styling updated');
            }).catch(err => {
              setError(err.message);
            }).finally(() => {
              setInProgress(false);
            });
          }}
        >
          Save
        </Button>
        {resetButton}
      </Stack>;
    }
    else if (!isDefault) {
      return resetButton;
    }
    else {
      return <Button
        variant='outlined'
        disabled
      >
        Default
      </Button>;
    }
  }

  const getPaletteActionButton = () => {
    const variables = {
      flowId: appId,
      palette: removeTypename(tempPalette)
    };
    return getActionButton(paletteHasChanged, variables, paletteResetButton, isDefaultPalette);
  }

  const getFontActionButton = () => {
    const variables = {
      flowId: appId,
      font: removeTypename(tempFont)
    };
    return getActionButton(fontHasChanged, variables, fontResetButton, isDefaultFont);
  }

  const getLayoutActionButton = () => {
    const variables = {
      flowId: appId,
      layout: removeTypename(tempLayout)
    };
    return getActionButton(!isEqual(layout, tempLayout), variables, layoutResetButton, isDefaultLayout);
  }

  const getDialogActionButton = () => {
    const variables = {
      flowId: appId,
      openAfter: tempOpenAfter,
    };
    return getActionButton(tempOpenAfter !== openAfter, variables, undefined, false);
  }

  const getIconActionButton = () => {
    const variables = {
      flowId: appId,
      icon: tempIcon,
    };
    return getActionButton(!isEqual(icon, tempIcon), variables, undefined, false);
  }

  if (!appId) return <Typography>Please save the app first.</Typography>

  return <Stack spacing={2}>
    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Color Palette</b></Typography>
      {getPaletteActionButton()}
    </Stack>
    {Object.entries(removeTypename(tempPalette)).map(([key, value]) => {
      return <MuiColorInput
        label={key}
        format='hex'
        key={key}
        value={value as string}
        onChange={updatedValue => setTempPalette({ ...tempPalette, [key]: updatedValue })}
      />
      { }
    })}

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Chat Dialog</b></Typography>
      {getDialogActionButton()}
    </Stack>
    <FormControlLabel
      label="Open On Load"
      control={<Switch
        checked={tempOpenAfter !== null}
        onChange={(_, checked) => {
          if (checked) {
            setTempOpenAfter(0);
          }
          else {
            setTempOpenAfter(null);
          }
        }}
      />}
    />
    {tempOpenAfter !== null && <TextField
      label='After'
      value={tempOpenAfter}
      type='number'
      InputProps={{
        inputProps: { min: 0, max: 300 },
        endAdornment: <InputAdornment position="end">seconds</InputAdornment>
      }}
      onChange={e => setTempOpenAfter(parseInt(e.target.value))}
    />}

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Chat Icon</b></Typography>
      {getIconActionButton()}
    </Stack>
    <FormControlLabel
      label="Use Custom Icon"
      control={<Switch
        checked={tempIcon !== null}
        onChange={(_, checked) => {
          if (checked) {
            setTempIcon({ lottieUrl: '' });
          }
          else {
            setTempIcon(null);
          }
        }}
      />}
    />
    {tempIcon !== null && <TextField
      label='Lottie URL'
      value={tempIcon.lottieUrl}
      placeholder='https://assets4.lottiefiles.com/packages/lf20_9zjzqz.json'
      onChange={e => setTempIcon({ lottieUrl: e.target.value })}
    />}

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Font</b></Typography>
      {getFontActionButton()}
    </Stack>
    <TextField
      label='Font Family'
      value={tempFont.fontFamily}
      onChange={e => setTempFont({ ...tempFont, fontFamily: e.target.value })}
    />
    <TextField
      label='Font Size'
      value={tempFont.fontSize}
      type='number'
      InputProps={{
        inputProps: { min: 8, max: 72 },
        endAdornment: <InputAdornment position="end">px</InputAdornment>
      }}
      onChange={e => setTempFont({ ...tempFont, fontSize: parseInt(e.target.value) })}
    />

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Layout</b></Typography>
      {getLayoutActionButton()}
    </Stack>
    <JSONInputField
      label='FAB Size'
      value={tempLayout.fabSize}
      onChange={v => setTempLayout({ ...tempLayout, fabSize: v })}
    />
    <JSONInputField
      label='FAB Spacing'
      value={tempLayout.fabSpacing}
      onChange={v => setTempLayout({ ...tempLayout, fabSpacing: v })}
    />
    <JSONInputField
      label='Dialog Width'
      value={tempLayout.dialogWidth}
      onChange={v => setTempLayout({ ...tempLayout, dialogWidth: v })}
    />
    <JSONInputField
      label='Dialog Height'
      value={tempLayout.dialogHeight}
      onChange={v => setTempLayout({ ...tempLayout, dialogHeight: v })}
    />
    <FormControlLabel
      control={<Switch checked={tempLayout.dialogDraggable} onChange={(_, checked) => setTempLayout({ ...tempLayout, dialogDraggable: checked })} />}
      label="Dialog Draggable"
    />
  </Stack>;
}
